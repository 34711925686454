import { Link } from "gatsby";
import React from "react";

export default function HomePageSecondComp() {
  return (
    <div className="bg-midBlue pb-32">
      <div className="w-10/12 sm:w-[80%] flex flex-col mx-auto items-center font-opensans xs:font-secondary max-w-5xl md:pt-8">
        {/* <div className="border-8 border-t border-orange px-8 my-20"></div> */}
        <h2
          className=" text-white text-center pb-16"
          style={{
            fontSize: "clamp(1.5rem, 3vw, 2.75rem)",
            lineHeight: "clamp(2rem, 4vw, 3.5rem)",
          }}
        >
          Why top businesses choose Tomedes
        </h2>
      </div>
      <div className="mx-auto md:w-11/12 xl:max-w-7xl w-11/12 lg:max-w-max">
        <div className="flex lg:flex-row flex-col  justify-between  flex-wrap ">
          <div className="w-full lg:w-[40%] pt-10">
            <h3 className="flex flex-col lg:flex-row items-center">
              <span className="text-80 font-bold text-orange">98%</span>
              <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
                Customer Satisfaction
              </span>
            </h3>
            <p className="text-white text-20 classImp text-center lg:text-left">
              Clients trust us for quality, speed, and accuracy.
            </p>
          </div>
          <div className="w-full lg:w-[40%] pt-10 mt-2 sm:mt-0">
            <h3 className="flex flex-col lg:flex-row items-center">
              <span className="text-80 font-bold text-orange">20,000</span>
              <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
                Professional Translators
              </span>
            </h3>
            <p className="text-white text-20 classImp text-center lg:text-left">
              Our highly trained certified translators and multilingual experts
              provide professional translation services tailored to our clients'
              industries.
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col  justify-between flex-wrap ">
          <div className="w-full lg:w-[40%] lg:pt-20 pt-10 mt-2 sm:mt-0 ">
            <h3 className="flex flex-col lg:flex-row items-center">
              <span className="text-80 font-bold text-orange  ">18</span>
              <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
                Years Of Experience
              </span>
            </h3>
            <p className="text-white text-20 classImp text-center lg:text-left 2xl:pl-[2px]">
              Nearly two decades of delivering world-class language solutions
              worldwide, including document translation, website localization,
              and interpreting services.
            </p>
          </div>
          <div className="w-full lg:w-[40%] pt-10 lg:pt-20 mt-2 sm:mt-0">
            <h3 className="flex flex-col lg:flex-row items-center">
              <span className="text-80 font-bold text-orange">150</span>
              <span className="text-20 sm:text-25 text-white font-opensans xs:font-secondary font-semibold lg:pl-8 classImp text-center lg:text-left">
                150 Languages & 950+ Language Pairs
              </span>
            </h3>
            <p className="text-white text-20 classImp text-center lg:text-left">
              Native human translators ensure cultural relevance for your
              message, from common to rare languages.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
