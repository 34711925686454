export const SliderData = [
  {
    heading:
      "How are we able to offer professional translations on a 24/7 basis?",
    para: "Globalization and modern technology are wonderful, aren't they? As our team works largely remotely, we are free to strategically recruit our translators, customer support staff and other team members from locations around the world. This means that we can follow the sun, with team members online in different time zones at different times.",
    id: "111",
    para2:
      "With branches and partnerships located around the world, we are able to provide professional translation services online 24/7. That’s right: we work around the clock, so that whenever you need us, we’re right here, working on your translations and providing full support. Goodbye, old fashioned eight-hour day. Hello, seamless 24/7 service.",
  },
  {
    heading: "What does our one-year guarantee mean?",
    para: "Our one-year guarantee means you can enjoy peace of mind. It applies to every translation we provide. If there's anything you're unhappy with about your translation, for up to a year after we deliver it to you, just let us know and we'll correct it right away. It's part of our commitment to being a translation company that provides a thorough, attentive service every step of the way.",
    id: "222",
  },
  {
    heading: "How do our professional translation services work?",
    para: "Our services are easy to use. They follow these simple steps:",
    id: "333",
    list: true,
  },
  {
    heading: "How do we ensure the quality of our translations?",
    para: "We ensure the quality of our services in several ways. First, we take time to recruit talented translators. These are individuals who not only have first-rate language skills, but who also demonstrate specialist knowledge and sector-specific expertise. We then match each translation job to the translator who best suits that project based on their experience, knowledge, and language skills. After each translation is complete, it is proofread by another professional before being handed off to our quality control team. Our chief editors then check for accuracy, precision, and consistency before delivering the project to you.",
    id: "444",
    para2:
      "And that's just for starters. As a responsible translation agency, we also routinely audit our translators' work and performance. Each translator is graded using our internal tracking system. We assess quality, professionalism and consistency, as well as daily translation output.",
    para3:
      "We also have a quality management system in place, to ensure the quality of each individual translation. Our tech team runs every translation through automated quality assurance checks. Then we then review the translation for compliance with the client's instructions (style, glossary, non-translatable elements and so forth). ",
    para4:
      "There is the option for external review by a proofreader or editor, if the client wishes, after which our translation agency tech team runs a further automated quality assurance check. One further option is to have the translation checked by an external linguist. Again, this is at the client’s discretion.",
    para5:
      "With all quality checks completed, the project manager undertakes a final review to ensure project compliance. We then hand over the top-notch translation to the client.",
  },
];
