import React from "react";
import { Link } from "gatsby";

function AboutUs() {
  return (
    <section className="w-full  bg-aboutus bg-no-repeat bg-cover bg-center py-36">
      <div className="w-11/12 lg:w-8/12 mx-auto  flex flex-col items-center justify-center max-w-7xl">
        <h2
          className="font-opensans xs:font-secondary font-bold text-midBlue  l:pr-[260px] mb-2 text-center"
          style={{
            fontSize: "clamp(1.5rem, 3vw, 2.75rem)",
            lineHeight: "clamp(2rem, 4vw, 3.5rem)",
          }}
        >
          About Our Translation Company
        </h2>
        <p
          className="font-opensans  text-midBlue mb-6 text-center mt-2"
          style={{
            fontSize: "clamp(1rem, 1.4vw, 1.25rem)",
            lineHeight: "clamp(1.5rem, 3vw, 1.75rem)",
          }}
        >
          Tomedes is a translation services company offering customized language
          and technology solutions for global enterprises. Since 2007, we have
          been committed to addressing all our customers' language-related
          needs. Our mission is to provide high-quality translation and
          localization services with an efficient hybrid approach that leverages
          the latest AI technologies and human expertise.
        </p>
        <Link
          to="/contactus.php"
          className="mt-2 uppercase rounded-full bg-orange text-white hover:bg-white hover:text-[#010101]  cursor-pointer text-17 font-semibold py-4 px-8 w-[253px] text-center transition-all duration-300"
        >
          Contact Us
        </Link>
      </div>
    </section>
  );
}

export default AboutUs;
