import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import Banner from "components/homepage/banner";
import HomePageSecondComp from "components/homepage/homepageSecondComp";
import HomepageThirdComp from "components/homepage/homepageThirdComp";
import HomepageFourthComp from "components/homepage/homePageFourthComp";
import HomepageFifthComp from "components/homepage/homepageFifthComp";
import HomepageReviews from "components/homepage/homepageReviews";
import Contact from "components/homepage/contact";
import ReliableTranslation from "components/homepage/reliableTranslation";
import SupportedLang from "components/homepage/supportedLanguages";
import AboutUs from "components/homepage/aboutus";
import HOMEPAGEFAQ from "components/homepage/faq";
import GetInTouch from "components/getInTouch";

function Index() {
  return (
    <Layout>
      <SEO
        title="Professional Translation Services - Translation Company"
        description="Professional translation services for any domain, language, and medium with a 1-year guarantee. Our translation company is ISO certified and online 24/7."
        keywords="translation, professional translation, translation services, language translation, translation company, translation agency"
        slug="/"
      />
      <Banner />
      <HomePageSecondComp />
      <HomepageThirdComp />
      <HomepageFourthComp />
      <HomepageFifthComp
        head="Trusted by"
        colorHead="95,000+"
        lastHeadText="Satisfied Clients Worldwide"
        content="World top companies but also small and middle businesses trust Tomedes as their trusted language translation partner. We are dedicated to exceeding expectations, every time."
        headingMobile="true"
      />
      <HomepageReviews />
      <Contact />
      <ReliableTranslation />
      <SupportedLang />
      <AboutUs />
      <HOMEPAGEFAQ />
      <GetInTouch />
    </Layout>
  );
}
export default Index;
