import React, { useState } from "react";
import { SliderData } from "./config";
import { Link } from "gatsby";

function FAQ() {
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const handleArrow = (id) => {
    if(selected === id){
      setOpen(!open)
      return;
    }
    setSelected(id);
    setOpen(true);
  };

  const chevronUp = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      class="bi bi-chevron-up"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
      />
    </svg>
  );

  const chevronDown = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      class="bi bi-chevron-down"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );

  return (
    <div className="flex items-center justify-center bg-midBlue py-14 md:py-24">
      <div className="flex items-center justify-center max-w-screen-xl px-4 md:px-0 flex-col w-full lg:w-7/12">
        <h2
          className="text-center font-opensans xs:font-secondary font-bold text-white  mb-10"
          style={{
            fontSize: "clamp(1.5rem, 3vw, 2.75rem)",
            lineHeight: "clamp(2rem, 4vw, 3.5rem)",
          }}
        >
          FAQs
        </h2>
        <p
          className="w-11/12 lg:w-full text-center font-opensans text-white  mb-10"
          style={{
            fontSize: "clamp(1rem, 1.4vw, 1.25rem)",
            lineHeight: "clamp(1.5rem, 3vw, 1.75rem)",
          }}
        >
          Got a question? No problem. We've covered the most common queries
          below. If there's anything else you want to know, simply &nbsp;
          <Link to="/contactus.php" className="text-[#ff7b16] underline">
            get in touch.
          </Link>
        </p>
        <div className="flex items-center justify-center w-full  flex-col ">
          {SliderData
            ? SliderData.map(
                ({ heading, para, id, para2, para3, para4, para5, list }) => (
                  <div
                    className="card w-11/12 lg:w-[120%]  flex justify-between mb-2 flex-col mt-3"
                    key={id}
                  >
                    <div
                      className="flex py-5 px-4  bg-[#00245A] justify-between items-center cursor-pointer rounded pl-[20px] pr-[10px] lg:pl-[42px] lg:pr-[20px]"
                      onClick={() => handleArrow(id)}
                    >
                      <h3
                        className={` text-[22px]  font-bold font-opensans xs:font-secondary my-auto mr-4 ${
                          selected === id && open ? "text-orange" : "text-white"
                        }`}
                      >
                        {heading}
                      </h3>
                      <span
                        className={`text-2xl text-white cursor-pointer ${
                          selected === id && open ? "text-orange" : "text-white"
                        }`}
                      >
                        {selected === id && open ? chevronUp : chevronDown}
                      </span>
                    </div>
                    {open && (
                      <div
                        className={`transition-all ease-in-out delay-150  duration-300 px-4 md:px-10 mt-8 mb-9 ${
                          selected === id ? "block" : "hidden"
                        }`}
                      >
                        <p className="text-[#e6e6e6] font-opensans leading-8 text-17">
                          {para}
                        </p>
                        {para2 && (
                          <p className="text-[#e6e6e6] font-opensans leading-8 text-17 mt-4">
                            {para2}
                          </p>
                        )}
                        {para3 && (
                          <p className="text-[#e6e6e6] font-opensans leading-8 text-17 mt-4">
                            {para3}
                          </p>
                        )}
                        {para4 && (
                          <p className="text-[#e6e6e6] font-opensans leading-8 text-17 mt-4">
                            {para4}
                          </p>
                        )}
                        {para5 && (
                          <p className="text-[#e6e6e6] font-opensans leading-8 text-17 mt-4">
                            {para5}
                          </p>
                        )}

                        {/* only for 3rd accordian */}
                        {list && (
                          <div className="text-[#e6e6e6] font-opensans leading-8 text-17 mt-4">
                            <div>
                              <p className="text-[#ff7b16] mb-4">
                                Step 1: Contact Channels
                              </p>
                              <ul
                                className="list-disc"
                                style={{ paddingLeft: "16px" }}
                              >
                                <li className="mb-8">
                                  Send us your translation project. There are 5
                                  ways to reach us:
                                  <ul
                                    className="list-disc"
                                    style={{ paddingLeft: "32px" }}
                                  >
                                    <li>
                                      Through the webforms and popups within the
                                      website
                                    </li>
                                    <li>
                                      Going through our quote process in our
                                      quote page
                                    </li>
                                    <li>
                                      Sending an email to &nbsp;
                                      <Link
                                        // to="/"
                                        href="mailto:support@tomedes.com"
                                        className="text-[#ff7b16] underline"
                                      >
                                        support@tomedes.com
                                      </Link>
                                    </li>
                                    <li>
                                      Calling +1 985 239 0142 if you're in the
                                      USA or UK: +44 1615 096140 if you're in
                                      the UK.
                                    </li>
                                    <li>Through WhatsApp</li>
                                  </ul>
                                </li>
                                <li className="mb-4">
                                  If you went through our quoting system, our
                                  website will walk you through a comprehensive
                                  process taking the necessary information
                                  required to translate your project in each
                                  step.
                                </li>
                                <li className="mb-4">
                                  For the rest of our contact channels, the
                                  project details will be gathered by your
                                  customer support manager to any of your
                                  preferred contact channels.
                                </li>
                              </ul>
                            </div>
                            <div>
                              <p className="text-[#ff7b16] mb-4">
                                Step 2: Project Management
                              </p>
                              <p className="mb-4 pl-4">
                                Your project manager will then analyze the
                                necessary resources to accomplish your project
                                and will endorse a quote for your approval. We
                                will review your translation requirements and
                                assign a native language translator with
                                expertise in your specific field.
                              </p>
                            </div>
                            <div>
                              <p className="text-[#ff7b16] mb-4">
                                Step 3: Translation Process
                              </p>
                              <p className="mb-4 pl-4">
                                Upon approval of the quote, we will proceed to
                                the translation process. The translator will
                                then finalize your translation.
                              </p>
                            </div>
                            <div>
                              <p className="text-[#ff7b16] mb-4">
                                Step 4: Quality Management
                              </p>
                              <p className="mb-4 pl-4">
                                Once finalized by the translator, your
                                translation project then will go through rounds
                                of automated and manual quality assurance by QA
                                experts.
                              </p>
                            </div>
                            <div>
                              <p className="text-[#ff7b16] mb-4">
                                Step 5: Delivery
                              </p>
                              <p className="mb-4 pl-4">
                                Your project will be delivered in the channel of
                                your choice or more secure methods such as SFTP.
                                We also provide post-completion support.
                              </p>
                            </div>
                            <p className="pl-4">
                              If you have a query at any stage of the process,
                              our team is on hand 24/7 to help.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
              )
            : null}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
