import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMidForm } from "hooks/Forms/useMidForm";
import { useLocation } from "@reach/router";
import ThankYouPopup from "components/popup/thankYouPopup";
import Loader from "components/loader";
import { Link } from "gatsby";
import formCommonPostData from "utils/form-common-post-data";
import { errorMessageEmail, patternEmail } from "utils/form-validation";

export default function Contact({ setOpen }) {
  const { status, mutateAsync } = useMidForm();
  const location = useLocation();
  const [thankOpen, setThankOpen] = useState(false);
  // const [checkBox, setCheckBox] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      email: data.email,
      pagelink: location.href,
      consent: data.consent,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
      setThankOpen(true);
    });
  };

  return (
    <section className="bg-midBlue flex items-center justify-center py-20 font-opensans classImpContact">
      <div className="flex items-center justify-center flex-col px-4 ">
        <h2
          className="font-opensans text-center xs:font-secondary font-semibold text-gray  max-w-5xl mx-auto"
          style={{
            fontSize: "clamp(1.5rem, 3vw, 2.75rem)",
            lineHeight: "clamp(2rem, 4vw, 3.5rem)",
          }}
        >
          {/* Contact Us 24/7 We respond within 15 minutes */}
          <span className="text-gray ">Contact Us 24/7</span> <br />{" "}
          <span className="text-gray ">We Will respond within 15 minutes</span>
        </h2>
        {/* <h2 className="font-opensans xs:font-secondary font-semibold text-40 centerImp md:hidden">
          <span className="text-orange md:pl-[24%] block">Contact Us 24/7</span>
          <span className="text-gray">We Will respond within 15 minutes</span>
        </h2> */}
        <p
          className="text-gray  font-opensans text-center pt-6  max-w-5xl mx-auto"
          style={{
            fontSize: "clamp(1rem, 1.4vw, 1.25rem)",
            lineHeight: "clamp(1.5rem, 3vw, 1.75rem)",
          }}
        >
          At Tomedes, our clients come first. Our global team provides superior
          customer service 24/7/365 all around the world. We offer
          cost-effective language translation services, respond quickly to
          inquiries, and back every project with a full-year accuracy warranty.
          We're here to make translation easy and reliable.
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="w-10/12">
          <div className="flex flex-wrap pt-6 px-0 md:flex md:flex-nowrap md:space-x-4 md:items-baseline w-full md:w-10/12 md:px-14 mx-auto relative">
            <input
              className="text-sm shadow appearance-none border rounded-lg w-full md:w-10/12 py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
              id="email"
              type="email"
              style={{ borderColor: errors.email ? "#DD5451" : "" }}
              placeholder="Enter email address here"
              {...register("email", {
                required: true,
                pattern: patternEmail,
              })}
            />
            {errors.email && (
              <span className="text-red text-xs pt-2 absolute top-[108px]">
                {errorMessageEmail}
              </span>
            )}
            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
            <button
              type="submit"
              id="middleform_submit"
              className="bg-orange rounded-lg hover:bg-newOrange-100 font-opensans font-bold text-white text-[16px] uppercase py-3 px-10 mx-auto mt-8 cursor-pointer transition-all duration-300 btn blue-submit"
            >
              {status === "loading" ? <Loader /> : "SUBMIT"}
            </button>
          </div>
          <div className="w-full md:w-10/12 md:px-14 mx-auto pt-5">
            <p className="text-sm text-white font-opensans pt-2">
              *I agree to Tomedes'&nbsp;
              <Link
                to="/privacy"
                className="text-orange hover:text-newOrange-100 underline transition-colors"
              >
                Privacy Policy
              </Link>
              &nbsp;by submitting this form.
            </p>
          </div>
        </form>
      </div>
      <ThankYouPopup
        open={thankOpen}
        setOpen={setThankOpen}
        setOpenPopUp={setOpen}
      />
    </section>
  );
}
