import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";

export default function SupportedLang() {
  return (
    <section className="bg-midBlue py-20 relative ">
      <div className="lg:grid lg:grid-cols-2 lg:mx-auto lg:max-w-screen-lg">
        <div className="container mx-auto px-4  lg:pb-10 md:w-[600px]">
          <h2
            className="text-white font-opensans xs:font-secondary  mb-8 lg:font-bold font-semibold"
            style={{
              fontSize: "clamp(1.5rem, 3vw, 2.75rem)",
              lineHeight: "clamp(2rem, 4vw, 3.5rem)",
            }}
          >
            Professional Translation in 150 Languages
          </h2>
          <p
            className="text-gray font-opensans  sm:px-5 lg:max-w-5xl lg:px-0 mb-8"
            style={{
              fontSize: "clamp(1rem, 1.4vw, 1.25rem)",
              lineHeight: "clamp(1.5rem, 3vw, 1.75rem)",
            }}
          >
            Our team of professional human translators provides expert services
            in over 150 languages and 950+ language pairs. From the most widely
            spoken languages to rare dialects, our native-speaking experts
            ensure your message is clear and culturally appropriate.
          </p>
          {/* <p
            className="text-white sm:text-lightGray font-opensans  sm:px-5 lg:max-w-5xl mt-2 lg:px-0 mb-16"
            style={{
              fontSize: "clamp(1rem, 1.4vw, 1.25rem)",
              lineHeight: "clamp(1.5rem, 3vw, 1.75rem)",
            }}
          >
            Tell us about your translation project. We love a tough challenge.
          </p> */}
          <Link
            to="/languages"
            className="uppercase learnmore-cn rounded-full bg-orange hover:bg-[#ff9a16] text-white cursor-pointer text-14 sm:text-17 font-semibold py-4 px-4 sm:px-8 w-[253px] text-center transition-all duration-300"
          >
            SEE OUR SUPPORTED LANGUAGES
          </Link>
        </div>
        <div className="lg:w-1/2"></div>
      </div>
      <div className="absolute right-0 bottom-0 hidden lg:block ">
        <OptimizedImage
          src="https://tomedes.gumlet.io/frontend/images/home-en/Language.webp"
          alt="globe"
          className="w-[470px] h-auto"
        />
      </div>
    </section>
  );
}
